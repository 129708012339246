<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- xicons/tabler/Mail -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <rect x="3" y="5" width="18" height="14" rx="2"></rect>
      <path d="M3 7l9 6l9-6"></path>
    </g>
  </svg>
</template>
