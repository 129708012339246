<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- xicons/tabler/BrandTelegram -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path
      d="M15 10l-4 4l6 6l4-16l-18 7l4 2l2 6l3-4"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>